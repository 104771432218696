import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';
import { Progress } from './ui/Progress';
import { Palette, User, Ruler, Wand2, Info, Edit } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/ToolTip';
import { Button } from './ui/Button';
import { supabase } from './supabaseClient';
import ColorService from './ColorService';
import { useNavigate } from 'react-router-dom';

const StyleProfileGenerator = () => {
  // State for profile completion percentage
  const [profileCompletion, setProfileCompletion] = useState(0);
  
  // State for user's style profile, including new fields
  const [styleProfile, setStyleProfile] = useState({
    colorAnalysis: null,
    faceShape: null,
    bodyType: null,
    gender: null,
    height: null,
    ageRange: null
  });
  
  const navigate = useNavigate();

  // Fetch user's style profile on component mount
  useEffect(() => {
    fetchStyleProfile();
  }, []);

  // Calculate profile completion percentage whenever styleProfile changes
  useEffect(() => {
    const completedFields = [
      styleProfile.colorAnalysis,
      styleProfile.faceShape,
      styleProfile.bodyType,
      styleProfile.gender,
      styleProfile.height,
      styleProfile.ageRange
    ].filter(Boolean).length;

    setProfileCompletion((completedFields / 6) * 100);
  }, [styleProfile]);

  // Function to fetch user's style profile data
  const fetchStyleProfile = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const fetchData = async (table) => {
      const { data, error } = await supabase
        .from(table)
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1);
      
      if (error) console.error(`Error fetching ${table}:`, error);
      return data && data.length > 0 ? data[0] : null;
    };

    const colorData = await fetchData('user_color_analyses');
    const faceData = await fetchData('user_face_shape_analyses');
    const bodyData = await fetchData('user_body_type_analyses');

    // Fetch user profile data for new fields
    const { data: profileData, error: profileError } = await supabase
      .from('user_profiles')
      .select('gender, height, age_range')
      .eq('id', user.id)
      .single();

    if (profileError) console.error('Error fetching user profile:', profileError);

    // Parse JSON strings
    const parseJsonField = (data, field) => {
      if (data && data[field]) {
        try {
          data[field] = JSON.parse(data[field]);
        } catch (error) {
          console.error(`Error parsing ${field}:`, error);
          data[field] = [];
        }
      }
    };

    parseJsonField(colorData, 'complementary_palette');
    parseJsonField(faceData, 'key_features');
    parseJsonField(bodyData, 'clothing_recommendations');

    setStyleProfile({
      colorAnalysis: colorData,
      faceShape: faceData,
      bodyType: bodyData,
      gender: profileData?.gender || null,
      height: profileData?.height || null,
      ageRange: profileData?.age_range || null
    });
  };

  // Function to get completion message based on profile completion percentage
  const getCompletionMessage = () => {
    if (profileCompletion === 100) return "Congratulations! AI Outfit Generator Unlocked!";
    if (profileCompletion >= 66) return "Almost there! Complete your profile to unlock AI Outfits";
    if (profileCompletion >= 33) return "Keep going! AI Outfit Generator awaits";
    return "Start your style journey to unlock AI Outfits";
  };

  // Component to display and edit basic profile information
  const BasicProfileInfo = ({ profile, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
      gender: profile.gender || '',
      height: profile.height || '',
      ageRange: profile.ageRange || ''
    });
    const [error, setError] = useState(null);
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setError("User not authenticated");
        return;
      }
  
      const heightInt = formData.height ? parseInt(formData.height, 10) : null;
  
      const { data: userData, error: userError } = await supabase
        .from('user_profiles')
        .select('email')
        .eq('id', user.id)
        .single();

      if (userError) {
        console.error('Error fetching user email:', userError);
        setError("Failed to fetch user data. Please try again.");
        return;
      }

      const { error } = await supabase
        .from('user_profiles')
        .upsert({ 
          id: user.id,
          email: userData.email, // Include the email in the upsert operation
          gender: formData.gender || null,
          height: heightInt,
          age_range: formData.ageRange || null
        }, { onConflict: 'id' });
  
      if (error) {
        console.error('Error updating profile:', error);
        setError("Failed to update profile. Please try again.");
      } else {
        onUpdate({
          gender: formData.gender || null,
          height: heightInt,
          ageRange: formData.ageRange || null
        });
        setIsEditing(false);
      }
    };


    if (isEditing) {
      return (
        <form onSubmit={handleSubmit} className="space-y-4 p-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Height (cm)</label>
            <input
              type="number"
              name="height"
              value={formData.height}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Age Range</label>
            <select
              name="ageRange"
              value={formData.ageRange}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Select age range</option>
              <option value="18-24">18-24</option>
              <option value="25-34">25-34</option>
              <option value="35-44">35-44</option>
              <option value="45-54">45-54</option>
              <option value="55+">55+</option>
            </select>
          </div>
          <div className="flex justify-end space-x-2 pt-4">
            <Button onClick={() => setIsEditing(false)} variant="outline">Cancel</Button>
            <Button type="submit">Save</Button>
          </div>
        </form>
      );
    }

    return (
      <div className="p-6 space-y-4">
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-800">Basic Profile Information</h3>
          <Button onClick={() => setIsEditing(true)} variant="ghost" className="p-1">
            <Edit className="h-5 w-5" />
          </Button>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm font-medium text-gray-500">Gender</p>
            <p className="mt-1 text-sm text-gray-900">{profile.gender || 'Not specified'}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-500">Height</p>
            <p className="mt-1 text-sm text-gray-900">{profile.height ? `${profile.height} cm` : 'Not specified'}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-500">Age Range</p>
            <p className="mt-1 text-sm text-gray-900">{profile.ageRange || 'Not specified'}</p>
          </div>
        </div>
      </div>
    );
  };

  // Function to render color content
  const renderColorContent = () => {
    if (!styleProfile.colorAnalysis) return <p className="text-gray-600">Color analysis not completed yet.</p>;
    const { season, sub_season } = styleProfile.colorAnalysis;
    const expandedPalette = ColorService.getExpandedPalette(season);

    return (
      <>
        <p className="mb-4 text-lg text-gray-700">Your color season: <strong className="text-indigo-600">{season}</strong> ({sub_season})</p>
        <div className="space-y-6">
          {Object.entries(expandedPalette).map(([role, colors]) => (
            <div key={role}>
              <h4 className="text-lg font-semibold mb-2 capitalize">{role} Colors</h4>
              <div className="flex flex-wrap gap-4">
                {colors.map((color, index) => (
                  <div key={index} className="flex flex-col items-center group">
                    <div 
                      className="w-12 h-12 rounded-full shadow-md transition-transform transform group-hover:scale-110" 
                      style={{ backgroundColor: color.hex }}
                    />
                    <span className="text-xs mt-2 opacity-0 group-hover:opacity-100 transition-opacity text-gray-600">{color.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  // Function to render face content
  const renderFaceContent = () => {
    if (!styleProfile.faceShape) return <p className="text-gray-600">Face shape analysis not completed yet.</p>;
    const { face_shape, key_features } = styleProfile.faceShape;
    return (
      <>
        <p className="mb-4 text-lg text-gray-700">Your face shape: <strong className="text-indigo-600">{face_shape}</strong></p>
        <ul className="space-y-4">
          {Array.isArray(key_features) && key_features.length > 0 ? (
            key_features.map((feature, index) => (
              <li key={index} className="bg-white p-4 rounded-lg shadow-sm">
                <h4 className="font-semibold text-gray-800 mb-2">{feature.feature}</h4>
                <p className="text-gray-600">{feature.description}</p>
              </li>
            ))
          ) : (
            <li className="text-gray-600">Key features data is not available or in an unexpected format.</li>
          )}
        </ul>
      </>
    );
  };

  // Function to render body content
  const renderBodyContent = () => {
    if (!styleProfile.bodyType) return <p className="text-gray-600">Body type analysis not completed yet.</p>;
    const { body_type, clothing_recommendations } = styleProfile.bodyType;
    return (
      <>
        <p className="mb-4 text-lg text-gray-700">Your body type: <strong className="text-indigo-600">{body_type}</strong></p>
        <h3 className="text-xl font-semibold text-gray-800 mb-4">Clothing Recommendations:</h3>
        <ul className="space-y-4">
          {Array.isArray(clothing_recommendations) && clothing_recommendations.length > 0 ? (
            clothing_recommendations.map((item, index) => (
              <li key={index} className="bg-white p-4 rounded-lg shadow-sm">
                <h4 className="font-semibold text-gray-800 mb-2">{item.item}</h4>
                <p className="text-gray-600">{item.description}</p>
              </li>
            ))
          ) : (
            <li className="text-gray-600">Clothing recommendations are not available or in an unexpected format.</li>
          )}
        </ul>
      </>
    );
  };

 // Main component render
 return (
  <div className="w-full bg-white rounded-xl shadow-sm p-6 mb-8">
    <CardHeader className="px-0 pb-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Wand2 className="h-8 w-8 text-indigo-500" />
          <div>
            <CardTitle className="text-2xl font-bold text-gray-800">
              Your AI-Generated Style Profile
            </CardTitle>
            <p className="text-sm text-gray-600 mt-1">
              Based on your color, face shape, and body type analyses
            </p>
          </div>
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Info className="h-5 w-5 text-gray-400 hover:text-gray-600" />
            </TooltipTrigger>
            <TooltipContent>
              Complete your style profile to unlock the AI Outfit Generator!
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </CardHeader>
    <CardContent className="px-0">
      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-medium text-gray-700">Profile Completion</span>
          <span className="text-sm font-medium text-indigo-600">{profileCompletion.toFixed(0)}%</span>
        </div>
        <Progress 
          value={profileCompletion} 
          className="w-full h-3 bg-gray-100 rounded-full" 
          indicatorClassName="bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full"
        />
        <p className="text-sm text-indigo-600 font-medium mt-2">{getCompletionMessage()}</p>
      </div>

      {profileCompletion === 100 && (
        <Button
          onClick={() => navigate('/outfit-generator')}
          className="w-full bg-gradient-to-r from-indigo-500 to-purple-500 text-white hover:from-indigo-600 hover:to-purple-600 transition-all duration-300 py-3 rounded-lg font-semibold mb-6"
        >
          Generate Your AI Outfit Now!
        </Button>
      )}

      {/* Basic Profile Information */}
      <Card className="mb-6">
        <BasicProfileInfo 
          profile={styleProfile} 
          onUpdate={(updatedData) => setStyleProfile(prev => ({ ...prev, ...updatedData }))}
        />
      </Card>

      <Tabs defaultValue="summary" className="w-full">
  <TabsList className="grid w-full grid-cols-4 sm:grid-cols-5 bg-gray-100 p-1 rounded-lg">
    <TabsTrigger value="summary" className="data-[state=active]:bg-white data-[state=active]:text-indigo-600 text-xs sm:text-sm">Summary</TabsTrigger>
    <TabsTrigger value="color" className="data-[state=active]:bg-white data-[state=active]:text-indigo-600 text-xs sm:text-sm">Color</TabsTrigger>
    <TabsTrigger value="face" className="data-[state=active]:bg-white data-[state=active]:text-indigo-600 text-xs sm:text-sm">Face Shape</TabsTrigger>
    <TabsTrigger value="body" className="data-[state=active]:bg-white data-[state=active]:text-indigo-600 text-xs sm:text-sm">Body Type</TabsTrigger>
    <TabsTrigger value="preferences" className="data-[state=active]:bg-white data-[state=active]:text-indigo-600 text-xs sm:text-sm">Preferences</TabsTrigger>
  </TabsList>

        <TabsContent value="summary">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <SummaryCard 
              title="Color Season" 
              icon={Palette} 
              value={styleProfile.colorAnalysis?.season} 
              subValue={styleProfile.colorAnalysis?.sub_season}
            />
            <SummaryCard 
              title="Face Shape" 
              icon={User} 
              value={styleProfile.faceShape?.face_shape}
            />
            <SummaryCard 
              title="Body Type" 
              icon={Ruler} 
              value={styleProfile.bodyType?.body_type}
            />
          </div>
        </TabsContent>

        <TabsContent value="color">
          <Card className="bg-white shadow-sm mt-6">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-gray-800">Your Color Palette</CardTitle>
            </CardHeader>
            <CardContent>
              {renderColorContent()}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="face">
          <Card className="bg-white shadow-sm mt-6">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-gray-800">Face Shape Analysis</CardTitle>
            </CardHeader>
            <CardContent>
              {renderFaceContent()}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="body">
          <Card className="bg-white shadow-sm mt-6">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-gray-800">Body Type Analysis</CardTitle>
            </CardHeader>
            <CardContent>
              {renderBodyContent()}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </CardContent>
  </div>
);
};

// SummaryCard component for displaying individual style attributes
const SummaryCard = ({ title, icon: Icon, value, subValue }) => (
<Card className="bg-gray-50 border-none shadow-none hover:bg-gray-100 transition-colors">
  <CardContent className="p-4">
    <div className="flex items-center space-x-3 mb-2">
      <Icon className="h-5 w-5 text-indigo-500" />
      <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
    </div>
    {value ? (
      <>
        <p className="text-xl font-bold text-gray-800">{value}</p>
        {subValue && <p className="text-sm text-gray-600">{subValue}</p>}
      </>
    ) : (
      <p className="text-sm text-gray-600">Not analyzed yet</p>
    )}
  </CardContent>
</Card>
);


export default StyleProfileGenerator;