import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/Card';
import { Button } from './ui/Button';
import { Tabs, TabsList, TabsTrigger } from './ui/Tabs';
import { Alert, AlertDescription, AlertTitle } from './ui/Alert';
import { Wand2, AlertCircle, Loader2, Save, CheckCircle } from 'lucide-react';
import { supabase } from './supabaseClient';
import ColorService from './ColorService';

const OutfitGenerator = ({ styleProfile = {} }) => {
  // State to store the local copy of the style profile
  const [localStyleProfile, setLocalStyleProfile] = useState(null);

  // State for outfit generation
  const [occasion, setOccasion] = useState('casual');
  const [generatedOutfit, setGeneratedOutfit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [sortedOutfit, setSortedOutfit] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Update local style profile when prop changes
  useEffect(() => {
    console.log("styleProfile prop changed:", styleProfile);
    if (styleProfile && Object.keys(styleProfile).length > 0) {
      setLocalStyleProfile(styleProfile);
    }
  }, [styleProfile]);

  // Log when local style profile updates
  useEffect(() => {
    console.log("localStyleProfile updated:", localStyleProfile);
  }, [localStyleProfile]);

  // Fetch user ID on component mount
  useEffect(() => {
    const getUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
      }
    };
    getUserId();
  }, []);

  // Define the order of outfit items
  const itemOrder = React.useMemo(() => ['top', 'bottom', 'shoes', 'accessory', 'styling_tips'], []);

  // Sort the generated outfit when it changes
  useEffect(() => {
    if (generatedOutfit) {
      const sorted = itemOrder
        .map(item => ({
          category: item,
          description: generatedOutfit[item]?.description || ''
        }))
        .filter(item => item.description);
      setSortedOutfit(sorted);
    }
  }, [generatedOutfit, itemOrder]);

  // Function to generate an outfit
  const generateOutfit = async () => {
    console.log("Generating outfit. User ID:", userId);
    console.log("Local Style Profile at generation:", localStyleProfile);

    // Check if user ID is available
    if (!userId) {
      setError("User ID not available. Please try again.");
      return;
    }

    // Check if style profile is available
    if (!localStyleProfile || Object.keys(localStyleProfile).length === 0) {
      console.log("Local Style Profile is null, undefined, or empty");
      setError("Style profile not available. Please complete your style profile first.");
      return;
    }

    // Check if style profile is complete
    if (!localStyleProfile.colorAnalysis || !localStyleProfile.faceShape || !localStyleProfile.bodyType) {
      console.log("Style Profile is incomplete:", localStyleProfile);
      setError("Style profile is incomplete. Please complete all analyses.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Get expanded color palette
      const season = localStyleProfile.colorAnalysis.season;
      const expandedPalette = ColorService.getExpandedPalette(season);

      // Make API request to generate outfit
      const response = await fetch('/api/generate-outfit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          colorPalette: expandedPalette,
          faceShape: localStyleProfile.faceShape?.face_shape || '',
          bodyType: localStyleProfile.bodyType?.body_type || '',
          occasion: occasion,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate outfit');
      }

      const data = await response.json();
      setGeneratedOutfit(data.outfit);
    } catch (error) {
      console.error('Error generating outfit:', error);
      setError(error.message || "An error occurred while generating the outfit. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to save the generated outfit
const saveOutfit = async () => {
  if (!generatedOutfit) {
    setError('No outfit to save. Generate an outfit first!');
    return;
  }

  setIsSaving(true);
  setError(null);
  setSaveSuccess(false);

  try {
    const { error } = await supabase
      .from('saved_outfits')
      .insert({
        user_id: userId,
        outfit_data: generatedOutfit,
      });

    if (error) throw error;

    setSaveSuccess(true);
  } catch (error) {
    console.error('Error saving outfit:', error);
    setError('Failed to save outfit. Please try again.');
  } finally {
    setIsSaving(false);
  }
};

  // Helper function to capitalize first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Render the component
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-2xl font-bold flex items-center">
          <Wand2 className="mr-2" />
          AI Outfit Generator
        </CardTitle>
        <CardDescription>
          Get personalized outfit recommendations based on your style profile
        </CardDescription>
      </CardHeader>
      <CardContent>
        {/* Occasion selection tabs */}
        <Tabs value={occasion} onValueChange={setOccasion} className="mb-4">
          <TabsList className="grid w-full grid-cols-4">
            <TabsTrigger value="casual">Casual</TabsTrigger>
            <TabsTrigger value="work">Work</TabsTrigger>
            <TabsTrigger value="formal">Formal</TabsTrigger>
            <TabsTrigger value="date">Date Night</TabsTrigger>
          </TabsList>
        </Tabs>

        {/* Generate outfit button */}
        <Button
          onClick={generateOutfit}
          disabled={isLoading || !userId}
          className="w-full mb-2"
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Generating Outfit...
            </>
          ) : (
            'Generate Outfit'
          )}
        </Button>

        {/* Save outfit button */}
        <Button
          onClick={saveOutfit}
          disabled={isSaving || !generatedOutfit}
          className="w-full"
        >
          {isSaving ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving Outfit...
            </>
          ) : (
            <>
              <Save className="mr-2 h-4 w-4" />
              Save Outfit
            </>
          )}
        </Button>

        {/* Error display */}
        {error && (
          <Alert variant="destructive" className="mt-4">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Success message */}
        {saveSuccess && (
          <Alert variant="success" className="mt-4">
            <CheckCircle className="h-4 w-4" />
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>Outfit saved successfully!</AlertDescription>
          </Alert>
        )}

        {/* Generated outfit display */}
        {sortedOutfit.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Your Personalized Outfit</h3>
            <ul className="space-y-2">
              {sortedOutfit.map(({ category, description }) => (
                <li key={category} className="bg-secondary p-3 rounded-lg">
                  <span className="font-medium mr-2">{capitalizeFirstLetter(category.replace('_', ' '))}:</span>
                  <span>{description}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default OutfitGenerator;